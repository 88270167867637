import { useAtomValue } from "jotai";

import {
  ButtonFlat,
  Button3DWrap,
  Button3D,
  ButtonRoundedCorners,
} from "@/commands/AssistantOnly/selectButton.style";
import { clientConfigAtom } from "@/utils/atoms";

type ChatOption = {
  label: string;
  value: string;
};

type Props = {
  option: ChatOption;
  isButtonVisible: boolean;
  onClick: () => void;
};

export function SelectButton({ option, isButtonVisible, onClick }: Props) {
  const client = useAtomValue(clientConfigAtom);

  let buttonContent;
  if (!isButtonVisible) return null;

  switch (client.buttonTheme?.[0]) {
    case "button_3d":
      buttonContent = (
        <Button3DWrap>
          <Button3D type="button" onClick={onClick} key={option.value}>
            <span>{option.label}</span>
          </Button3D>
        </Button3DWrap>
      );
      break;
    case "button_roundedCorners":
      buttonContent = (
        <ButtonRoundedCorners
          type="button"
          onClick={onClick}
          key={option.value}
        >
          <span>{option.label}</span>
        </ButtonRoundedCorners>
      );
      break;
    default:
      buttonContent = (
        <ButtonFlat type="button" onClick={onClick} key={option.value}>
          <span>{option.label}</span>
        </ButtonFlat>
      );
      break;
  }

  return buttonContent;
}
